import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Footer from "../components/footer";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import { Link, useHistory } from "react-router-dom";

import { Check } from "@material-ui/icons";
import { findByLabelText } from "@testing-library/dom";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import axios from "axios";

// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";

const StyledTableCell = withStyles((theme) => ({
  head: {
    //   backgroundColor: "#132C87",
    backgroundColor: theme.palette.common.white,

    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  homeContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(19,44,135)",
    padding: 20,
    marginTop: 40,
  },
  iconGroup: {
    marginTop: 30,
    marginBottom: 30,
    display: "flex",
    justifyContent: "space-evenly",
  },
  notice: {
    display: "inline-flex",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  centerHoriz: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  historical: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(243, 158, 26)",
    padding: 20,
    marginTop: 40,
    // opacity: 0.6,
  },
  alignLeft: {
    marginTop: 30,
    display: "flex",
    justifyContent: "start",
  },
  marginRight: {
    marginRight: 20,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  linkDark: {
    textDecoration: "none",
    color: "rgba(19,44,135)",
  },
}));

function SurveyText(language, institution) {
  const classes = useStyles();

  if (language == "en") {
    let text = configuration.Dashboard["survey-text"]
      .replace("{{University}}", institution)
      .split("{{Icons}}");
    return (
      <>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text[0]}
        </Typography>
        <div className={classes.iconGroup}>
          <div className={classes.notice}>
            <CheckCircleIcon style={{ color: "#2FC57D" }} />{" "}
            <Typography className={classes.paddingLeft} variant="body1">
              {configuration.StatusTooltips["Complete"]}
            </Typography>
          </div>
          <div className={classes.notice}>
            <ErrorIcon />{" "}
            <Typography className={classes.paddingLeft} variant="body1">
              {configuration.StatusTooltips["Incomplete"]}
            </Typography>
          </div>
        </div>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text[1]}
        </Typography>
      </>
    );
  } else {
    let text = configurationFR.Dashboard["survey-text"]
      .replace("{{University}}", institution)
      .split("{{Icons}}");
    return (
      <>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text[0]}
        </Typography>
        <div className={classes.iconGroup}>
          <div className={classes.notice}>
            <CheckCircleIcon style={{ color: "#2FC57D" }} />{" "}
            <Typography className={classes.paddingLeft} variant="body1">
              {configurationFR.StatusTooltips["Complete"]}
            </Typography>
          </div>
          <div className={classes.notice}>
            <ErrorIcon />{" "}
            <Typography className={classes.paddingLeft} variant="body1">
              {configurationFR.StatusTooltips["Incomplete"]}
            </Typography>
          </div>
        </div>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text[1]}
        </Typography>
      </>
    );
  }
}

function Dashboard(props) {
  const classes = useStyles();
  const history = useHistory();
  const validYears = configuration.ValidYears;

  const [loading, setLoading] = useState(true);
  const [completionData, setCompletionData] = useState([]);

  const getFormData = () => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/user/dashboard_stats", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: props.user.institution,
        },
      })
      .then((response) => {
        console.log("success", response);
        setCompletionData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const formStatusFragment = (value) => {
    switch (value) {
      case 0:
        return (
          <Tooltip
            title={
              props.language == "en" ? (
                <>{configuration.StatusTooltips["NotStarted"]}</>
              ) : (
                <>{configurationFR.StatusTooltips["NotStarted"]}</>
              )
            }
          >
            <HighlightOffIcon style={{ color: "#FA8178" }} />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip
            title={
              props.language == "en" ? (
                <>{configuration.StatusTooltips["Incomplete"]}</>
              ) : (
                <>{configurationFR.StatusTooltips["Incomplete"]}</>
              )
            }
          >
            <ErrorIcon />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip
            title={
              props.language == "en" ? (
                <>{configuration.StatusTooltips["Complete"]}</>
              ) : (
                <>{configurationFR.StatusTooltips["Complete"]}</>
              )
            }
          >
            <CheckCircleIcon style={{ color: "#2FC57D" }} />
          </Tooltip>
        );
    }
  };

  useEffect(() => {
    console.log(props.user);
    // get submissions tracking data
    getFormData(validYears.slice(-1)[0]);
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        {props.language == "en" ? (
          <>{configuration.Dashboard["page-title"]}</>
        ) : (
          <>{configurationFR.Dashboard["page-title"]}</>
        )}
      </Typography>
      <div className={classes.homeContainer}>
        <Typography variant="h6">
          {props.language == "en" ? (
            <>{configuration.Dashboard["survey-title"]}</>
          ) : (
            <>{configurationFR.Dashboard["survey-title"]}</>
          )}
        </Typography>
        <br />
        {SurveyText(props.language, props.user.institution)}
        <div className={classes.centerHoriz}>
          <Button variant="contained" color="primary" disableElevation>
            <Link id="start" to={"/collections"} className={classes.link}>
              {props.language == "en" ? (
                <>{configuration.Dashboard["button-text"]}</>
              ) : (
                <>{configurationFR.Dashboard["button-text"]}</>
              )}
            </Link>
          </Button>
        </div>
      </div>
      <div className={classes.historical}>
        <Typography variant="h6">
          {props.language == "en" ? (
            <>{configuration.Dashboard["history-title"]}</>
          ) : (
            <>{configurationFR.Dashboard["history-title"]}</>
          )}
        </Typography>
        <br />
        <Typography variant="body1">
          {props.language == "en" ? (
            <>{configuration.Dashboard["history-text"]}</>
          ) : (
            <>{configurationFR.Dashboard["history-text"]}</>
          )}
        </Typography>
        {!loading ? (
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {props.language == "en" ? (
                      <>{configuration.HistoricalTableSupplement[0]}</>
                    ) : (
                      <>{configurationFR.HistoricalTableSupplement[0]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[7]}</>
                    ) : (
                      <>{configurationFR.Navigation[7]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[0]}</>
                    ) : (
                      <>{configurationFR.Navigation[0]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[1]}</>
                    ) : (
                      <>{configurationFR.Navigation[1]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[2]}</>
                    ) : (
                      <>{configurationFR.Navigation[2]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.HistoricalTableSupplement[1]}</>
                    ) : (
                      <>{configurationFR.HistoricalTableSupplement[1]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[3]}</>
                    ) : (
                      <>{configurationFR.Navigation[3]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[4]}</>
                    ) : (
                      <>{configurationFR.Navigation[4]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[5]}</>
                    ) : (
                      <>{configurationFR.Navigation[5]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {props.language == "en" ? (
                      <>{configuration.Navigation[6]}</>
                    ) : (
                      <>{configurationFR.Navigation[6]}</>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">&nbsp;</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {completionData.reverse().map((row) => (
                  <StyledTableRow key={row.institution}>
                    <StyledTableCell component="th" scope="row">
                      {row.year}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.key_statistics)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.collections)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.services)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.personnel)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.salaries)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.facilities)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.expenditures)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.enrolment)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(row.information)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Button
                        variant="outlined"
                        color="primary"
                        disableElevation
                        className={classes.marginRight}
                      >
                        <Link
                          id="start"
                          to={"/history?yr=" + row.year}
                          className={classes.linkDark}
                        >
                          {props.language == "en" ? (
                            <>{configuration.HistoricalTableSupplement[2]}</>
                          ) : (
                            <>{configurationFR.HistoricalTableSupplement[2]}</>
                          )}
                        </Link>
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              width: "50%",
              margin: "0 auto",
              marginTop: 20,
              // marginLeft: -250,
              textAlign: "center",
            }}
          >
            <Typography component="p" style={{ fontWeight: "lighter" }}>
              Loading submission data.
            </Typography>
            <CircularProgress style={{ margin: "3rem" }} />
          </div>
        )}
        {/* <div className={classes.alignLeft}>
          {validYears.map((year) => (
            <Button
              variant="outlined"
              color="primary"
              disableElevation
              className={classes.marginRight}
            >
              <Link
                id="start"
                to={"/history?yr=" + year}
                className={classes.linkDark}
              >
                {year}
              </Link>
            </Button>
          ))}
        </div> */}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(withStyles(), connect(mapStateToProps, {}))(Dashboard);
