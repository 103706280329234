import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Footer from "../components/footer";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import { Check } from "@material-ui/icons";
import { findByLabelText } from "@testing-library/dom";

// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";

import qs from "qs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  homeContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(0,0,0,0.6)",
    padding: 20,
    marginTop: 40,
  },
  iconGroup: {
    marginTop: 30,
    marginBottom: 30,
    display: "flex",
    justifyContent: "space-evenly",
  },
  notice: {
    display: "inline-flex",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  centerHoriz: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  historical: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(243, 158, 26)",
    padding: 20,
    marginTop: 40,
    // opacity: 0.6,
  },
  alignLeft: {
    marginTop: 30,
    display: "flex",
    justifyContent: "start",
  },
  marginRight: {
    marginRight: 20,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  linkDark: {
    textDecoration: "none",
    color: "rgba(19,44,135)",
  },
  completedStatus: {
    color: "#2FC57D",
    fontWeight: "medium",
  },
  incompleteStatus: {
    color: "#F39E1A",
    fontWeight: "medium",
  },
  notStartedStatus: {
    color: "#B00020",
    fontWeight: "medium",
  },
}));

const formStatusFragment = (value) => {
  switch (value) {
    case 0:
      return <HighlightOffIcon style={{ color: "#FA8178" }} />;
      break;
    case 1:
      return <ErrorIcon />;
      break;
    case 2:
      return <CheckCircleIcon style={{ color: "#2FC57D" }} />;
      break;
  }
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    //   backgroundColor: "#132C87",
    backgroundColor: theme.palette.common.white,

    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

function SurveyText(language, year) {
  const classes = useStyles();

  if (language == "en") {
    let text = configuration.History["text"].replace("{{Year}}", year);
    return (
      <>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text}
        </Typography>
      </>
    );
  } else {
    let text = configurationFR.History["text"].replace("{{Year}}", year);
    return (
      <>
        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          {text}
        </Typography>
      </>
    );
  }
}

function History(props) {
  const classes = useStyles();
  const history = useHistory();
  let [loading, setLoading] = useState(true);
  const [completionData, setCompletionData] = useState([]);

  const year = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  }).yr;

  useEffect(() => {
    console.log(props.user);
    if (configuration.ValidYears.includes(year)) {
      getFormData(year);
    }
  }, [
    qs.parse(props.location.search, {
      ignoreQueryPrefix: true,
    }).yr,
  ]);

  const getFormData = (yearSelection) => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/form-status", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          institution: props.user.institution,
          id: yearSelection,
        },
      })
      .then(({ data }) => {
        console.log("forms", data);
        setCompletionData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4">
        {props.language == "en" ? (
          <>
            {configuration.History["title"]}: {year}
          </>
        ) : (
          <>
            {configurationFR.History["title"]}: {year}
          </>
        )}
      </Typography>
      <div className={classes.homeContainer}>
        {SurveyText(props.language, year)}
        {year && configuration.ValidYears.includes(year) ? (
          <>
            {!loading ? (
              <TableContainer>
                {props.language == "en" ? (
                  <>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            {configuration.History.columns[0]}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {configuration.History.columns[1]}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {configuration.History.columns[2]}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {configuration.Navigation.map((row, index) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {completionData.some(
                                (e) => e.name === row.replace(/\s+/g, "")
                              ) ? (
                                <>
                                  {completionData.find(
                                    (e) => e.name === row.replace(/\s+/g, "")
                                  ).submitted ? (
                                    <div className={classes.completedStatus}>
                                      {configuration.History.status[2]}{" "}
                                    </div>
                                  ) : (
                                    <div className={classes.incompleteStatus}>
                                      {configuration.History.status[1]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={classes.notStartedStatus}>
                                  {configuration.History.status[0]}{" "}
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                              >
                                <Link
                                  id="start"
                                  to={
                                    "/" +
                                    configuration.Navigation[index].split(
                                      " "
                                    )[0] +
                                    "?yr=" +
                                    year
                                  }
                                  className={classes.linkDark}
                                >
                                  {row}
                                </Link>
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                ) : (
                  <>
                    <Table
                      className={classes.table}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>
                            {configurationFR.History.columns[0]}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {configurationFR.History.columns[1]}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {configurationFR.History.columns[2]}
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {configurationFR.Navigation.map((row, index) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              {completionData.some(
                                (e) =>
                                  e.name === configuration.Navigation[index]
                              ) ? (
                                <>
                                  {completionData.find(
                                    (e) =>
                                      e.name === configuration.Navigation[index]
                                  ).submitted ? (
                                    <div className={classes.completedStatus}>
                                      {configurationFR.History.status[2]}
                                    </div>
                                  ) : (
                                    <div className={classes.incompleteStatus}>
                                      {configurationFR.History.status[1]}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={classes.notStartedStatus}>
                                  {configurationFR.History.status[0]}
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              <Button
                                variant="outlined"
                                color="primary"
                                disableElevation
                              >
                                <Link
                                  id="start"
                                  to={
                                    "/" +
                                    configuration.Navigation[index] +
                                    "?yr=" +
                                    year
                                  }
                                  className={classes.linkDark}
                                >
                                  {row}
                                </Link>
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}
              </TableContainer>
            ) : (
              <div
                style={{
                  width: "50%",
                  margin: "0 auto",
                  marginTop: 20,
                  // marginLeft: -250,
                  textAlign: "center",
                }}
              >
                <Typography component="p" style={{ fontWeight: "lighter" }}>
                  Loading historical data.
                </Typography>
                <CircularProgress style={{ margin: "3rem" }} />
              </div>
            )}
          </>
        ) : (
          <>
            {props.language == "en" ? (
              <>{configuration.History["invalid"]}</>
            ) : (
              <>{configurationFR.History["invalid"]}</>
            )}
          </>
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(withStyles(), connect(mapStateToProps, {}))(History);
