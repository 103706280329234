import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import { connect } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ErrorIcon from "@material-ui/icons/Error";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import ReactExport from "react-export-excel-xlsx-fix";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { Chart as ChartJS } from "chart.js/auto";
import { Line } from "react-chartjs-2";
// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";
import { useLocation } from "react-router-dom";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 280,
    marginTop: 64,
    padding: 40,
  },
  homeContainer: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(19,44,135)",
    padding: 20,
    marginTop: 40,
  },
  iconGroup: {
    marginTop: 30,
    marginBottom: 30,
    display: "flex",
    justifyContent: "space-evenly",
  },
  notice: {
    display: "inline-flex",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  centerHoriz: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
  },
  historical: {
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: 5,
    borderTop: "10px solid rgba(243, 158, 26)",
    padding: 20,
    marginTop: 40,
    // opacity: 0.6,
  },
  alignLeft: {
    marginTop: -80,
    marginBottom: 50,
    position: "relative",
    display: "flex",
    justifyContent: "end",
    // height: "100%",
  },
  yearDropdown: {
    marginRight: 20,
  },
  alignSelect: {
    marginTop: -60,
    display: "flex",
    justifyContent: "end",
  },
  marginRight: {
    marginRight: 20,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
}));

// Table info
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.white,
    },
  },
}))(TableRow);

function Reports(props) {
  const classes = useStyles();
  const validYears = configuration.ValidYears;
  const history = useHistory();
  let [loading, setLoading] = useState(true);
  const [completionData, setCompletionData] = useState([]);
  const [downloadSalariesButton, setDownloadSalariesButton] = useState([
    "Download Salaries",
  ]);
  const [downloadDataButton, setDownloadDataButton] = useState([
    "Download Data",
  ]);
  const [downloadFormsButton, setDownloadFormsButton] = useState([
    "Download Forms",
  ]);
  const ExcelLink = React.createRef();
  const ExcelLinkSalaries = React.createRef();
  const ExcelLinkData = React.createRef();

  const [downloadedForms, setDownloadedForms] = useState([]);
  const [downloadedFormsReady, setDownloadedFormsReady] = useState(false);
  const [downloadedFormsDataReady, setDownloadedFormsDataReady] =
    useState(false);

  const [formsColumns, setformsColumns] = useState([]);
  const [formsData, setformsData] = useState([]);
  const [formsStatsYear, setformsStatsYear] = useState(validYears.slice(-1)[0]);

  const [groupNames, setGroupNames] = useState([
    "All Groups",
    "CARL",
    "CAUL",
    "OCUL",
    "COPPUL",
  ]);

  const [formsStatsYearDownload, setformsStatsYearDownload] = useState(
    validYears.slice(-1)[0]
  );

  const [formsStatsGroupSelect, setFormsStatsGroupSelect] = useState(
    groupNames[0]
  );

  const [downloadedSalaries, setDownloadedSalaries] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);

  const [salaryColumns, setSalaryColumns] = useState([
    "Institution",
    "Staff ID",
    "Salary",
    "Category",
    "Professional Experience (Years)",
    "Professional Experience in Reporting Insitution (Years)",
  ]);

  const [expColumns, setExpColumns] = useState([
    "Year Start | Début de l'exercice | financial_year_start",
    "Year End | Fin de l'exercice | financial_year_end",
    "Titles held (all formats) |  Titres détenus (tous formats) | q_1",
    "One time resource purchases | Achats ponctuels de documents | q_2_1",
    "Ongoing resource purchases | Achats permanents de documents | q_2_2",
    "Collection support | Soutien des collections | q_2_3",
    "Total library materials | Total, documents de bibliothèque | q_2_4",
    "Professional staff | Personnel professionnel | q_3_1",
    "Support staff | Personnel de soutien | q_3_2",
    "Casual staff | Personnel temporaire | q_3_3",
    "Total staffing expenditures | Total, dépenses en personnel | q_3_4",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]);

  const [sal_1, setSal_1] = useState([
    {
      value: "Institution",
      widthPx: 160,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        font: { bold: true },
        alignment: { horizontal: "center" },
      },
    },
    {
      value: "Number of staff",
      widthPx: 120,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        alignment: { horizontal: "center" },
      },
    },
    {
      value: "Average Years of experience",
      widthPx: 160,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        alignment: { horizontal: "center" },
      },
    },
    {
      value: "Average years in reporting library",
      widthPx: 160,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        alignment: { horizontal: "center" },
      },
    },
    {
      value: "Median salary",
      widthPx: 120,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        alignment: { horizontal: "center" },
      },
    },
    {
      value: "Average salary",
      widthPx: 120,
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "F2D5D6" } },
        alignment: { horizontal: "center" },
      },
    },
  ]);

  const [sal_1DataReady, setSal_1DataReady] = useState(false);

  const [salaryDataReady, setSalaryDataReady] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const [clickedForm, setClickedForm] = useState("");
  const [clickedOrg, setClickedOrg] = useState("");
  const [clickedSubmissionDate, setClickedSubmissionDate] = useState("");
  const [clickedSubmissionEmail, setClickedSubmissionEmail] = useState("");

  const location = useLocation();

  const [overviewChartData, setOverviewChartData] = useState({
    labels: configuration.ValidYears,
    datasets: [
      {
        label: "Loading Dataset...",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: [0, 0, 0, 0],
      },
    ],
  });
  const [overviewChartDataStaff, setOverviewChartDataStaff] = useState({
    labels: configuration.ValidYears,
    datasets: [
      {
        label: "Loading Dataset...",
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        data: [0, 0, 0, 0],
      },
    ],
  });

  const [lineOptions, setLineOptions] = useState({
    scales: {
      y: {
        // beginAtZero: true,
        title: {
          display: true,
          text: "Dollars",
        },
      },
    },
  });

  const [staffLineOptions, setStaffLineOptions] = useState({
    scales: {
      y: {
        // beginAtZero: true,
        title: {
          display: true,
          text: "Number of People",
        },
      },
    },
  });

  const handleOpenUnlock = (value, form, organization) => {
    setOpenMenu(true);
    setClickedForm(form.charAt(0).toUpperCase() + form.slice(1));

    setClickedOrg(organization);
    getSpecificForm(form.charAt(0).toUpperCase() + form.slice(1), organization);
  };

  useEffect(() => {
    // This code will run whenever the location (route) changes
    // window.location.reload();
  }, [location]);

  const handleViewSalaries = () => {
    // redirect the user to /salary_csv?yr={formsStatsYear}&inst={clickedOrg}
    const url =
      "salary_csv?yr=" +
      formsStatsYear +
      "&inst=" +
      clickedOrg +
      "&email=" +
      clickedSubmissionEmail;
    window.open(url, "_blank");
  };

  const handleUnlock = () => {
    postUnlockForm(clickedForm, clickedOrg, formsStatsYear);
    handleCloseUnlock();
  };

  const handleCloseUnlock = () => {
    setOpenMenu(false);
    setClickedForm("");
    setClickedOrg("");
    setClickedSubmissionDate("");
    setClickedSubmissionEmail("");
  };

  const handleChange = (event) => {
    setformsStatsYear(event.target.value);
    getFormData(event.target.value);
    setLoading(true);
  };

  const handleChangeDownloadYear = (event) => {
    setformsStatsYearDownload(event.target.value);
    // just set state, no download triggered.
  };

  const handleChangeGroupSelect = (event) => {
    setFormsStatsGroupSelect(event.target.value);
    // just set state, no download triggered.
  };

  const getFormData = (yearSelection) => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/admin/stats", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: yearSelection,
        },
      })
      .then((response) => {
        console.log("success", response);
        setCompletionData(response.data);
        // UNCOMMENT THIS TO ENABLE LOADING
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const getCollectionsData = () => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/admin/getcollectionsdata", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          group: formsStatsGroupSelect,
        },
      })
      .then((response) => {
        console.log("success", response);

        setOverviewChartData({
          labels: configuration.ValidYears,
          datasets: response.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const getPersonnelData = () => {
    const token = localStorage.token;
    axios
      .get(process.env.REACT_APP_API + "/admin/getpersonneldata", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          group: formsStatsGroupSelect,
        },
      })
      .then((response) => {
        console.log("success personnel", response);

        setOverviewChartDataStaff({
          labels: configuration.ValidYears,
          datasets: response.data,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
      });
  };

  const getAllForms = () => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/admin/formdownload", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: formsStatsYearDownload,
          group: formsStatsGroupSelect,
        },
      })
      .then((response) => {
        console.log("success", response.data.length);
        console.log("data: ", response.data);
        setDownloadedForms(response.data);

        setDownloadFormsButton("Building Sheet...");
        console.log(downloadedForms);

        // reset form colums
        setformsColumns([]);

        // write all column names for every form type.
        //  get the first form from every index in downloadedForms, then build the column list for that form:
        //    for j in downloadedForms[i].formData: columns.push(j.question)
        for (let j = 0; j < response.data.length; j++) {
          // allow for case where no forms of a type are submitted
          if (response.data[j].data[0]) {
            let _temp = response.data[j].data[0].formData;
            let _singlePage = ["Institution"];
            for (const [key, value] of Object.entries(_temp)) {
              _singlePage.push(String(value.question));
              // columns for footnotes are added after every question.
              _singlePage.push("FOOTNOTES for:" + String(value.question));
            }
            setformsColumns((formsColumns) => [...formsColumns, _singlePage]);
          } else {
            setformsColumns((formsColumns) => [...formsColumns, ["none"]]);
          }
        }

        // create excel pages for Collections, Services, Personnel, Salaries, Facilities, Expenditures, Enrolment, and Information

        // Fill out column titles with INSTITUTION, Q1, Q2, ... based on form JSONS collected
        // Fill in answers from downloaded forms
        // Download spreadsheet to user's machine

        for (let k = 0; k < response.data.length; k++) {
          let _aggregatetemp = [];
          for (let m = 0; m < response.data[k].data.length; m++) {
            let _tempdata = response.data[k].data[m].formData;
            let _singlePagedata = [response.data[k].data[m].institution];
            for (const [key, value] of Object.entries(_tempdata)) {
              _singlePagedata.push(String(value.answer));
              // if there is a footnote, add data here. Otherwise add empty entry.
              if (value.answer_notes) {
                _singlePagedata.push(String(value.answer_notes));
              } else {
                _singlePagedata.push("");
              }
            }
            _aggregatetemp.push(_singlePagedata);
          }
          setformsData((formsData) => [...formsData, _aggregatetemp]);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setDownloadFormsButton("Error!");
        setTimeout(() => {
          setDownloadFormsButton("Download Forms");
        }, 2000);
      });
  };

  // download all form data in Excel Format with Pages
  const downloadForms = () => {
    // set button state as loading..
    setDownloadFormsButton("Fetching...");
    // fetch all form data for 2019-2020
    getAllForms();
    console.log(formsColumns);
  };

  const getSalaryForms = () => {
    const token = localStorage.token;
    console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/admin/salariesdownload", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: formsStatsYearDownload,
          group: formsStatsGroupSelect,
        },
      })
      .then((response) => {
        console.log("success", response.data[0]);
        setDownloadSalariesButton("Building Sheet...");

        // data should be preformatted for general, CARL=1, CAUL=2, OCUL=3, COPPUL=4
        setDownloadedSalaries(response.data[0]);
      })
      .catch((error) => {
        console.log("error", error);
        setDownloadSalariesButton("Error!");
        setTimeout(() => {
          setDownloadSalariesButton("Download Forms");
        }, 2000);
      });
  };

  const getDataForms = () => {
    const token = localStorage.token;
    axios
      .get(process.env.REACT_APP_API + "/admin/datadownload", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: formsStatsYearDownload,
          group: formsStatsGroupSelect,
        },
      })
      .then((response) => {
        console.log("success", response.data[0]);
        setDownloadDataButton("Building Sheet...");

        // data should be preformatted for general, CARL=1, CAUL=2, OCUL=3, COPPUL=4
        setDownloadedData(response.data[0]);
      })
      .catch((error) => {
        console.log("error", error);
        setDownloadDataButton("Error!");
        setTimeout(() => {
          setDownloadDataButton("Download Data");
        }, 2000);
      });
  };

  // download all salary data in Excel Format
  const downloadSalaries = () => {
    // set button state as loading..
    setDownloadSalariesButton("Fetching...");
    // fetch all salary data
    // create sheets for: general, CARL=1, CAUL=2, OCUL=3, COPPUL=4
    // add institution column, Staff ID, Salary, Category, Professional Experience (Years),
    //  Professional Experience in Reporting Insitution (Years),
    // fill in answers from endpoint
    // Download spreadsheet to user's machine
    getSalaryForms();
  };

  const downloadData = () => {
    // set button state as loading..
    setDownloadDataButton("Fetching...");
    // fetch all aggregated data
    getDataForms();
  };

  // plot salary data on bar graph
  const salaryGraph = () => {
    // bar graph with expenditures from:
    //  5.1.5 - Total Collections
    //  5.2.5 - Total salaries
    //  5.4 - Other expenditures
    //  5.5 - Total library expenditures
    // personnel form:
    //  3.5 total staff FTE
    // run calculations on the backend and just send back values for a given year.
  };

  const getSpecificForm = (form, organization) => {
    const token = localStorage.token;
    // console.log(token);
    axios
      .get(process.env.REACT_APP_API + "/admin/getformmetadata", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: formsStatsYearDownload,
          form: form,
          institution: organization,
        },
      })
      .then((response) => {
        console.log("success for getformmetadata", response);
        // Populate modal with data
        setClickedSubmissionDate(response.data.submissionDate);
        setClickedSubmissionEmail(response.data.submissionUser);
        // setClickedSubmissionEmail("beans");
      })
      .catch((error) => {
        // currently fails silently - needs a snackbar
        console.log("error", error);
      });
  };

  const postUnlockForm = (form, organization, year) => {
    const token = localStorage.token;
    // console.log(token);
    axios
      .post(process.env.REACT_APP_API + "/admin/unlockform", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.token}`,
        },
        params: {
          id: year,
          form: form,
          institution: organization,
        },
      })
      .then(function (response) {
        console.log("success unlockform", response);
        getFormData(formsStatsYear);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formStatusFragment = (value, form, organization) => {
    switch (value) {
      case 0:
        return (
          <Tooltip title="Not submitted">
            <HighlightOffIcon style={{ color: "#FA8178" }} />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip title="In Progress">
            <ErrorIcon />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip title="Submitted">
            <CheckCircleIcon
              onClick={() => handleOpenUnlock(value, form, organization)}
              style={{ color: "#2FC57D", borderRadius: "50%", padding: 0 }}
              onMouseOver={(event) =>
                (event.currentTarget.style.boxShadow =
                  "0px 4px 10px 0px rgba(0, 0, 0, 0.15)")
              }
              onMouseOut={(event) =>
                (event.currentTarget.style.boxShadow = "none")
              }
            />
          </Tooltip>
        );
      case 3:
        return (
          <Tooltip title="Incorrect Format!">
            <ErrorIcon
              onClick={() => handleOpenUnlock(value, form, organization)}
              style={{ color: "#FFCC00", borderRadius: "50%", padding: 0 }}
              onMouseOver={(event) =>
                (event.currentTarget.style.boxShadow =
                  "0px 4px 10px 0px rgba(0, 0, 0, 0.15)")
              }
              onMouseOut={(event) =>
                (event.currentTarget.style.boxShadow = "none")
              }
            />
          </Tooltip>
        );
    }
  };

  useEffect(() => {
    console.log(props.user);

    // get submissions tracking data
    getFormData(validYears.slice(-1)[0]);
    getCollectionsData();
    getPersonnelData();
  }, []);

  useEffect(() => {
    // called when formsColumns is altered.
    // if all forms have been added, report this.
    if (formsColumns.length === 7) {
      setDownloadedFormsReady(true);
    }
  }, [formsColumns]);

  useEffect(() => {
    // called when formsData is altered.
    // if all forms have been added, report this.
    if (formsData.length === 7) {
      setDownloadedFormsDataReady(true);
      setDownloadFormsButton("Done!");
      setTimeout(() => {
        setDownloadFormsButton("Download Forms");
        setDownloadedFormsDataReady(false);
        setformsData([]);
      }, 2000);
    }
  }, [formsData]);

  useEffect(() => {
    // called when downloadedSalaries is altered
    if (downloadedSalaries.length === 5) {
      setSalaryDataReady(true);
      console.log("RUN DOWNLOAD");
      console.log(salaryColumns);
      setDownloadSalariesButton("Done!");
      setTimeout(() => {
        setDownloadSalariesButton("Download Salaries");
        setSalaryDataReady(false);
      }, 2000);
    }
  }, [downloadedSalaries]);

  useEffect(() => {
    // called when downloadedData is altered
    console.log("RUN DOWNLOAD");
    console.log(downloadedData);

    if (downloadedData.length >= 1) {
      setSal_1DataReady(true);
      console.log(sal_1);
      setDownloadSalariesButton("Done!");
      setTimeout(() => {
        setDownloadSalariesButton("Download Salaries");
        setSal_1DataReady(false);
      }, 2000);
    }
  }, [downloadedData]);

  return (
    <div className={classes.root}>
      <Typography variant="h4">Admin and Reports</Typography>
      <div className={classes.homeContainer}>
        <Typography variant="h6">
          Submission Tracking: {formsStatsYear}
        </Typography>
        <br />
        <div className={classes.alignSelect}>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={formsStatsYear}
              label="Year"
              onChange={handleChange}
            >
              {validYears.map((year) => (
                <MenuItem value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
          Review below which institutions have submitted some or all of their
          forms.
        </Typography>
        <br />

        {!loading ? (
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Institution</StyledTableCell>
                  <StyledTableCell align="right">
                    Key Statistics
                  </StyledTableCell>
                  <StyledTableCell align="right">Collections</StyledTableCell>
                  <StyledTableCell align="right">Services</StyledTableCell>
                  <StyledTableCell align="right">Personnel</StyledTableCell>
                  <StyledTableCell align="right">Salaries</StyledTableCell>
                  <StyledTableCell align="right">Facilities</StyledTableCell>
                  <StyledTableCell align="right">Expenditures</StyledTableCell>
                  <StyledTableCell align="right">Enrolment</StyledTableCell>
                  <StyledTableCell align="right">Information</StyledTableCell>
                  <StyledTableCell align="right">&nbsp;</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {completionData.map((row) => (
                  <StyledTableRow key={row.institution}>
                    <StyledTableCell component="th" scope="row">
                      {row.institution}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.keyStatistics,
                        "keyStatistics",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.collections,
                        "collections",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.services,
                        "services",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.personnel,
                        "personnel",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.salaries,
                        "salaries",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.facilities,
                        "facilities",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.expenditures,
                        "expenditures",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.enrolment,
                        "enrolment",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formStatusFragment(
                        row.information,
                        "information",
                        row.institution
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {row.user ? (
                        <a href={"mailto:" + row.user}>
                          <MailOutlineIcon />
                        </a>
                      ) : (
                        <MailOutlineIcon />
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div
            style={{
              width: "50%",
              margin: "0 auto",
              marginTop: 20,
              // marginLeft: -250,
              textAlign: "center",
            }}
          >
            <Typography component="p" style={{ fontWeight: "lighter" }}>
              Loading submission data.
            </Typography>
            <CircularProgress style={{ margin: "3rem" }} />
          </div>
        )}
      </div>
      <div className={classes.historical}>
        <Typography variant="h6">General Reporting</Typography>
        <br></br>
        <div>
          <br />

          {/* <Typography variant="body1" style={{ marginTop: 0 }}>
            Aggregate reports for{" "}
            <b>
              <u>{formsStatsYearDownload}</u>
            </b>{" "}
            and for{" "}
            <b>
              <u>{formsStatsGroupSelect}</u>
            </b>
          </Typography> */}
        </div>

        <div>
          <div className={classes.alignLeft}>
            <div className={classes.yearDropdown}>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-label">Group</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formsStatsGroupSelect}
                  label="Group"
                  onChange={handleChangeGroupSelect}
                >
                  {groupNames.map((group) => (
                    <MenuItem value={group}>{group}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={classes.yearDropdown}>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-label">Year</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formsStatsYearDownload}
                  label="Year"
                  onChange={handleChangeDownloadYear}
                >
                  {validYears.map((year) => (
                    <MenuItem value={year}>{year}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <Button
              className={classes.marginRight}
              variant="contained"
              color="primary"
              disableElevation
              onClick={downloadSalaries}
            >
              {downloadSalariesButton}
            </Button>
            <Button
              className={classes.marginRight}
              variant="contained"
              color="primary"
              onClick={downloadForms}
              disableElevation
            >
              {downloadFormsButton}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={downloadData}
              disableElevation
            >
              {downloadDataButton}
            </Button>
          </div>
        </div>

        <div style={{ marginTop: "30px" }}>
          <Typography variant="subtitle1">
            Mean Total Collections for All Groups from Expenditures Q 5.1.5
          </Typography>

          <Line data={overviewChartData} options={lineOptions} />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography variant="subtitle1">
            Mean Total Staff FTE for All Groups from Personnel Q 3.5
          </Typography>

          <Line data={overviewChartDataStaff} options={staffLineOptions} />
        </div>
      </div>
      {downloadedFormsReady && downloadedFormsDataReady && (
        <ExcelFile
          filename={
            formsStatsGroupSelect + "_" + formsStatsYearDownload + "_AllForms"
          }
          className="hidden"
          hideElement="true"
          ref={ExcelLink}
        >
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[0],
                data: formsData[0],
              },
            ]}
            name="Collections"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[1],
                data: formsData[1],
              },
            ]}
            name="Services"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[2],
                data: formsData[2],
              },
            ]}
            name="Personnel"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[3],
                data: formsData[3],
              },
            ]}
            name="Facilities"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[4],
                data: formsData[4],
              },
            ]}
            name="Expenditures"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[5],
                data: formsData[5],
              },
            ]}
            name="Enrolment"
          />
          <ExcelSheet
            dataSet={[
              {
                columns: formsColumns[6],
                data: formsData[6],
              },
            ]}
            name="Institution"
          />
        </ExcelFile>
      )}

      {salaryDataReady && (
        <ExcelFile
          filename={formsStatsYearDownload + "_Salaries"}
          className="hidden"
          hideElement="true"
          ref={ExcelLinkSalaries}
        >
          <ExcelSheet
            dataSet={[
              {
                columns: salaryColumns,
                data: downloadedSalaries[0],
              },
            ]}
            name={groupNames[0]}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: salaryColumns,
                data: downloadedSalaries[1],
              },
            ]}
            name={groupNames[1]}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: salaryColumns,
                data: downloadedSalaries[2],
              },
            ]}
            name={groupNames[2]}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: salaryColumns,
                data: downloadedSalaries[3],
              },
            ]}
            name={groupNames[3]}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: salaryColumns,
                data: downloadedSalaries[4],
              },
            ]}
            name={groupNames[4]}
          />
        </ExcelFile>
      )}

      {sal_1DataReady && (
        <ExcelFile
          filename={formsStatsYearDownload + "_CARL_Data"}
          className="hidden"
          hideElement="true"
          ref={ExcelLinkData}
        >
          <ExcelSheet
            dataSet={[
              {
                columns: ["Salary data for all professionals"],
                data: [""],
              },
              {
                columns: sal_1,
                data: downloadedData[0],
              },
            ]}
            name={"Sal 1"}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: ["Salary data for for administrative librarians"],
                data: [""],
              },
              {
                columns: sal_1,
                data: downloadedData[1],
              },
            ]}
            name={"Sal 2"}
          />
          <ExcelSheet
            dataSet={[
              {
                columns: ["Salary data for for non-administrative librarians"],
                data: [""],
              },
              {
                columns: sal_1,
                data: downloadedData[2],
              },
            ]}
            name={"Sal 3"}
          />
        </ExcelFile>
      )}

      <Dialog
        open={openMenu}
        onClose={handleCloseUnlock}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Information about: {clickedOrg} | {clickedForm}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Form Status:</div>
              <div>LOCKED</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Submission Date:</div>
              <div>
                {moment(clickedSubmissionDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Submitted By:</div>
              <div> {clickedSubmissionEmail}</div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {clickedForm !== "Salaries" && (
            <Button onClick={handleUnlock} color="primary">
              Unlock
            </Button>
          )}
          {clickedForm == "Salaries" && (
            <Button onClick={handleViewSalaries} color="primary">
              View
            </Button>
          )}
          <Button onClick={handleCloseUnlock} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
  };
}

export default compose(withStyles(), connect(mapStateToProps, {}))(Reports);
