import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { ListItemAvatar, Avatar } from "@material-ui/core";
import DashIcon from "@material-ui/icons/Dashboard";
import DialpadIcon from "@material-ui/icons/Dialpad";
import GroupIcon from "@material-ui/icons/Group";
import AdminIcon from "@material-ui/icons/Security";
import PersonIcon from "@material-ui/icons/Person";
import LogoIcon from "@material-ui/icons/VoiceChat";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import compose from "recompose/compose";
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, TextField } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import formSchemaImport from "../form_schemas/collections.json";
import { withRouter } from "react-router";

import { fetchForms } from "../actions/postActions";
import qs from "qs";

// general configuration for languages
import configuration from "../form_schemas/configuration.json";
import configurationFR from "../form_schemas/configuration-fr.json";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#fff",
    marginTop: 64,
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  listItemLink: {
    textDecoration: "none",
    color: "#000",
  },
  profileListItem: {
    marginTop: "auto",
  },
  profileListItemAvatar: {
    paddingLeft: 16,
  },
  drawerFooter: {
    display: "flex",
    marginRight: "16px",
    justifyContent: "space-between",
    position: "fixed",
    bottom: 15,
    width: drawerWidth - 15,
  },
  navHeader: {
    marginTop: 40,
    paddingLeft: 36,
    fontSize: 12,
  },
  leftPadding: {
    paddingLeft: 20,
  },
  centerHoriz: {
    display: "flex",
    justifyContent: "space-between",
  },
  hr: {
    marginTop: 40,
    border: "0.5px solid rgba(0, 0, 0, 0.12)",
    width: "100%",
    borderWidth: "inherit",
  },
  active: {
    backgroundColor: "rgba(0,0,0,1)",
  },
}));

function Navbar(props) {
  const classes = useStyles();
  const history = useHistory();
  const validYears = configuration.ValidYears;
  const [year, setYear] = useState(validYears.slice(-1)[0]);

  // dialogue message sending box states
  const [openMenu, setOpenMenu] = useState(false);
  // include user's email and organization
  // include form name box
  const [formName, setFormName] = useState("");
  const [message, setMessage] = useState("");
  // include an image upload button (optional)
  const [image, setImage] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarError, setOpenSnackbarError] = useState(false);

  const [pathname, setPathname] = useState("");

  const handleImageChange = (e) => {
    console.log(e.target.files[0].name);
    setImage(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log("form submitted");
    console.log("form name: ", formName);
    console.log("message: ", message);
    console.log("image: ", image);
    console.log("user: ", props.user.username);

    const reader = new FileReader();
    if (image) {
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        const base64Image = reader.result;
        console.log(base64Image);
        axios
          .post(process.env.REACT_APP_API + "/issue_report", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.token}`,
            },
            params: {
              institution: props.user.institution,
              pageTitle: formName,
              message: message,
              image: base64Image,
              user: props.user.username,
              imageName: image.name,
            },
          })
          .then(function (response) {
            // populate icons relevant to form props.formstatus
            // setStatus(response.data);
            console.log(response.data);
            setOpenMenu(false);
            setFormName("");
            setMessage("");
            setImage(null);
            setOpenSnackbar(true);
          })
          .catch(function (error) {
            //   console.log(error.data);
            console.log(error);
            setOpenSnackbarError(true);
          });
      };
    } else {
      axios
        .post(process.env.REACT_APP_API + "/issue_report", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${localStorage.token}`,
          },
          params: {
            institution: props.user.institution,
            pageTitle: formName,
            message: message,
            image: null,
            user: props.user.username,
          },
        })
        .then(function (response) {
          // populate icons relevant to form props.formstatus
          // setStatus(response.data);
          console.log(response.data);
          setOpenMenu(false);
          setFormName("");
          setMessage("");
          setImage(null);
          setOpenSnackbar(true);
        })
        .catch(function (error) {
          //   console.log(error.data);
          console.log(error);
          setOpenSnackbarError(true);
        });
    }
  };

  // const [props.formstatus, setStatus] = React.useState(props.formprops.formstatus);

  const location = useLocation();

  useEffect(() => {
    try {
      setPathname(location.pathname);
      console.log("location.pathname", location.pathname);
    } catch {
      console.log("error in location.pathname");
    }

    console.log("pathname", pathname);
  }, [location]);

  // useEffect(() => {
  //   // loadCurrentForm();
  //   fetchForms(props.user.institution, props.year);
  //   console.log("load forms for year: ", props.year);
  // }, [props.year]);

  // useEffect(() => {
  //   // loadCurrentForm();
  //   fetchForms(props.user.institution, props.year);
  //   let queryYear = qs.parse(window.location.search, {
  //     ignoreQueryPrefix: true,
  //   }).yr;
  //   console.log("load forms for year: ", queryYear);
  // }, []);

  useEffect(() => {
    // choose which form to import
    console.log("The URL has changed");
    let queryYear = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).yr;
    // run through validYears and load in the appropriate form.
    for (let i = 0; i < validYears.length; i++) {
      console.log("QUERY YEAR: ", queryYear, validYears[i]);
      if (queryYear === validYears[i]) {
        props.fetchForms(props.user.institution, validYears[i]);
        console.log("load forms for year: ", queryYear, validYears[i]);
        setYear(queryYear);
        break;
      } else if (!queryYear) {
        // always default to most recent year
        props.fetchForms(props.user.institution, validYears.slice(-1)[0]);
        console.log("load forms for current year: ", queryYear);
        setYear(validYears.slice(-1)[0]);
        break;
      } else if (!validYears.includes(queryYear)) {
        // if query contains invalid year, display error
        console.log("not fetching forms");
        break;
      }
    }
  }, [
    qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).yr,
  ]);

  const handleOpenMessage = () => {
    setOpenMenu(true);
  };

  const handleCloseMessage = () => {
    setOpenMenu(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <Typography variant="overline" className={classes.navHeader}>
          Forms
        </Typography>
        <List classes={{ selected: classes.active }}>
          <Link
            id="Key"
            to={"/key?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/key" == pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[7]}</>
                  ) : (
                    <>{configurationFR.Navigation[7]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "KeyStatistics" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "KeyStatistics" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Collections"
            to={"/collections?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/collections" == pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[0]}</>
                  ) : (
                    <>{configurationFR.Navigation[0]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Collections" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Collections" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Services"
            to={"/services?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem button selected={"/services" === pathname}>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[1]}</>
                  ) : (
                    <>{configurationFR.Navigation[1]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Services" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Services" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Personnel"
            to={"/personnel?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/personnel" === pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[2]}</>
                  ) : (
                    <>{configurationFR.Navigation[2]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Personnel" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Personnel" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Facilities"
            to={"/facilities?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/facilities" === pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[3]}</>
                  ) : (
                    <>{configurationFR.Navigation[3]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Facilities" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Facilities" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Expenditures"
            to={"/expenditures?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/expenditures" === pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[4]}</>
                  ) : (
                    <>{configurationFR.Navigation[4]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Expenditures" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Expenditures" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Enrolment"
            to={"/enrolment?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/enrolment" === pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[5]}</>
                  ) : (
                    <>{configurationFR.Navigation[5]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Enrolment" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Enrolment" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
          <Link
            id="Institution"
            to={"/institution?yr=" + year}
            className={classes.listItemLink}
          >
            <ListItem selected={"/institution" === pathname} button>
              <ListItemText className={classes.leftPadding}>
                <div className={classes.centerHoriz}>
                  {props.language == "en" ? (
                    <>{configuration.Navigation[6]}</>
                  ) : (
                    <>{configurationFR.Navigation[6]}</>
                  )}{" "}
                  {props.formstatus.some(
                    (obj) => obj.name === "Institution" && obj.submitted
                  ) && <CheckCircleIcon style={{ color: "#2FC57D" }} />}
                  {props.formstatus.some(
                    (obj) => obj.name === "Institution" && !obj.submitted
                  ) && <ErrorIcon />}
                </div>
              </ListItemText>
            </ListItem>
          </Link>
        </List>

        {props.user.institution === "CARL-ABRC" && (
          <>
            <hr className={classes.hr} />
            <Typography variant="overline" className={classes.navHeader}>
              Admin Panel
            </Typography>
            <List>
              <Link
                id="reports"
                to={"/reports"}
                className={classes.listItemLink}
              >
                <ListItem button>
                  <ListItemText
                    className={classes.leftPadding}
                    primary={"Reports"}
                  />
                </ListItem>
              </Link>
              <Link
                id="institutions"
                to={"/institutions"}
                className={classes.listItemLink}
              >
                <ListItem button>
                  <ListItemText
                    className={classes.leftPadding}
                    primary={"Institutions"}
                  />
                </ListItem>
              </Link>
              <Link
                id="whitelist"
                to={"/whitelist"}
                className={classes.listItemLink}
              >
                <ListItem button>
                  <ListItemText
                    className={classes.leftPadding}
                    primary={"Whitelist"}
                  />
                </ListItem>
              </Link>
            </List>
          </>
        )}

        <hr className={classes.hr} />
        <Typography variant="overline" className={classes.navHeader}>
          Help & Support
        </Typography>
        <List style={{ marginBottom: 100 }}>
          <ListItem button onClick={handleOpenMessage}>
            <ListItemText
              className={classes.leftPadding}
              primary={"Report an Issue"}
            />
          </ListItem>
        </List>

        {/* <hr className={classes.hr} />
        <Typography variant="overline" className={classes.navHeader}>
          History
        </Typography>
        <List>
          <Link id="2019" to={"/history/2019"} className={classes.listItemLink}>
            <ListItem button>
              <ListItemText
                className={classes.leftPadding}
                primary={"2019-2020"}
              />
            </ListItem>
          </Link>
          <Link id="2018" to={"/history/2018"} className={classes.listItemLink}>
            <ListItem button>
              <ListItemText
                className={classes.leftPadding}
                primary={"2018-2019"}
              />
            </ListItem>
          </Link>
        </List> */}
      </Drawer>

      <Dialog
        open={openMenu}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Report an Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Form Status:</div>
              <div>LOCKED</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Submission Date:</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Submitted By:</div>
            </div> */}

            <Typography variant="subtitle2">
              If you encounter any issues with your form submissions, please
              fill out the form below and we will get back to you as soon as
              possible with a solution.
            </Typography>
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Your Email: </div>
              <div>{props.user.username}</div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>Your Institution: </div>
              <div>{props.user.institution}</div>
            </div>
            <br />
            <Typography variant="body1">
              Which page were you having an issue with?
            </Typography>
            <TextField
              label="Page Name"
              value={formName}
              style={{ width: "100%", marginTop: 10 }}
              variant="outlined"
              onChange={(e) => setFormName(e.target.value)}
            />
            <br />
            <br />
            <Typography variant="body1">
              Please describe the issue below.
            </Typography>
            <TextField
              multiline
              rows={4}
              style={{ width: "100%", marginTop: 10 }}
              label="Message"
              value={message}
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
            />
            <br />
            <br />
            <Typography variant="body1">
              If relevant, please upload a screenshot of the issue.
            </Typography>
            <input accept="image/*" type="file" onChange={handleImageChange} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            disabled={message.length < 5}
          >
            Send
          </Button>
          <Button onClick={handleCloseMessage} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        message="Message Submitted"
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        ContentProps={{
          style: { backgroundColor: "rgb(47, 197, 125)" },
        }}
      />
      <Snackbar
        open={openSnackbarError}
        message="Error! Please try again."
        autoHideDuration={5000}
        onClose={() => setOpenSnackbarError(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        ContentProps={{
          style: { backgroundColor: "red" },
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    language: state.language,
    formstatus: state.formstatus,
    year: state.year,
  };
}

export default withRouter(
  compose(withStyles(), connect(mapStateToProps, { fetchForms }))(Navbar)
);
